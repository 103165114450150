<ng-template #holidayEdit let-modal>
  <div class="modal-header">
    <h4 class="m-0" i18n="@@Page.MasterData.Holidays.Edit">[i18n] Edit holiday</h4>
    <button type="button" class="btn modal-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <svg class="bi action-icon" fill="currentColor">
        <use xlink:href="assets/icons.svg#x-circle"/>
      </svg>
    </button>
  </div>
  <div class="modal-body">
    <form id="holidayForm" [formGroup]="holidayForm" (ngSubmit)="save()" #form="ngForm">
      <div class="row g-4">
        <div class="col-lg-12">
          <label for="title" class="form-label" i18n="@@DTO.HolidayDto.Title">[i18n] Title</label>
          <input id="title"
                 type="text"
                 class="form-control"
                 [readonly]="isReadOnly"
                 placeholder="[i18n] Title"
                 i18n-placeholder="@@DTO.HolidayDto.TitlePlaceholder"
                 formControlName="title"
                 ngbAutofocus>
        </div>
        <div class="col-lg-3">
          <label for="startDate" class="form-label" i18n="@@DTO.HolidayDto.StartDate">[i18n] Start date</label>
          <input id="startDate"
                 type="text"
                 inputmode="none"
                 tsDatePicker
                 class="form-control"
                 [readonly]="isReadOnly"
                 placeholder="[i18n] Begin"
                 i18n-placeholder="@@DTO.HolidayDto.StartDatePlaceholder"
                 formControlName="startDate"
                 autocomplete="off">
        </div>
        <div class="col-lg-3">
          <label for="endDate" class="form-label" i18n="@@DTO.HolidayDto.EndDate">[i18n] End date</label>
          <input id="endDate"
                 type="text"
                 inputmode="none"
                 tsDatePicker
                 relativeAnchor="end"
                 class="form-control"
                 [readonly]="isReadOnly"
                 placeholder="[i18n] End"
                 i18n-placeholder="@@DTO.HolidayDto.EndDatePlaceholder"
                 formControlName="endDate"
                 autocomplete="off">
        </div>
        <div class="col-lg-6">
          <label for="type" class="form-label" i18n="@@DTO.HolidayDto.Type">[i18n] Type</label>
          <ng-select id="type"
                     labelForId="type"
                     [selectOnTab]="true"
                     [readonly]="isReadOnly"
                     formControlName="type">
            <ng-option value="holiday" i18n="@@Enum.HolidayType.Holiday">[i18n] Holidays / Vacation</ng-option>
            <ng-option value="publicHoliday" i18n="@@Enum.HolidayType.PublicHoliday">[i18n] Public holiday</ng-option>
          </ng-select>
        </div>
        <div class="col-lg-6" *ngIf="authorizationEnabled">
          <!--suppress XmlInvalidId -->
          <label for="userId" class="form-label" i18n="@@DTO.TimeSheetDto.UserId">[i18n] User</label>
          <ng-select [items]="allUsers"
                     [selectOnTab]="true"
                     [clearable]="false"
                     [readonly]="isUserReadOnly"
                     bindValue="id"
                     bindLabel="value"
                     labelForId="userId"
                     placeholder="[i18n] Select user"
                     i18n-placeholder="@@DTO.TimeSheetDto.UserIdPlaceholder"
                     formControlName="userId">
            <ng-template ng-option-tmp let-item="item">
              <span [ngClass]="{'text-secondary': item.hidden}">{{item.value}}</span>
            </ng-template>
          </ng-select>
        </div>
      </div>
      <hr class="text-secondary" *ngIf="form.submitted && form.invalid">
      <ts-form-validation-errors></ts-form-validation-errors>
    </form>
  </div>
  <div class="modal-footer">
    <button id="cancel" form="holidayForm" type="button" class="btn btn-secondary me-3" (click)="modal.close()">
      <ng-container i18n="@@Common.Cancel">[i18n] Cancel</ng-container>
    </button>
    <button id="save" form="holidayForm" type="submit" class="btn btn-primary" [disabled]="isReadOnly">
      <svg class="bi check-lg" fill="currentColor">
        <use xlink:href="assets/icons.svg#check-lg"/>
      </svg>
      <ng-container i18n="@@Common.Save">[i18n] Save</ng-container>
    </button>
  </div>
</ng-template>
