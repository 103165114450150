<ng-template #activityEdit let-modal>
  <div class="modal-header">
    <h4 class="m-0" i18n="@@Page.MasterData.Activities.Edit">[i18n] Edit activity</h4>
    <button type="button" class="btn modal-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <svg class="bi action-icon" fill="currentColor">
        <use xlink:href="assets/icons.svg#x-circle"/>
      </svg>
    </button>
  </div>
  <div class="modal-body">
    <form id="activityForm" [formGroup]="activityForm" (ngSubmit)="save()" #form="ngForm">
      <div class="row g-4">
        <div class="col-lg-12">
          <label for="title" class="form-label" i18n="@@DTO.ActivityDto.Title">[i18n] Name</label>
          <input id="title"
                 type="text"
                 class="form-control"
                 [readonly]="isReadOnly"
                 placeholder="[i18n] Activity name"
                 i18n-placeholder="@@DTO.ActivityDto.TitlePlaceholder"
                 formControlName="title"
                 ngbAutofocus>
        </div>
        <div class="col-lg-6">
          <!--suppress XmlInvalidId -->
          <label for="customerName" class="form-label" [tsOptionalLabel] i18n="@@DTO.ActivityDto.Customer">[i18n] Customer</label>
          <ng-select [items]="customers$ | async"
                     [selectOnTab]="true"
                     [readonly]="customerReadonly || isReadOnly"
                     bindValue="id"
                     bindLabel="value"
                     labelForId="customerName"
                     formControlName="customerId">
            <ng-template ng-option-tmp let-item="item">
              <span [ngClass]="{'text-secondary': item.hidden}">{{item.value}}</span>
            </ng-template>
          </ng-select>
        </div>
        <div class="col-lg-6">
          <!--suppress XmlInvalidId -->
          <label for="projectName" class="form-label" [tsOptionalLabel] i18n="@@DTO.ActivityDto.Project">[i18n] Project</label>
          <ng-select [items]="projects$ | async"
                     [selectOnTab]="true"
                     [readonly]="isReadOnly"
                     bindValue="id"
                     bindLabel="value"
                     labelForId="projectName"
                     (change)="projectChanged($event)"
                     formControlName="projectId">
            <ng-template ng-option-tmp let-item="item">
              <span [ngClass]="{'text-secondary': item.hidden}">{{item.value}}</span>
            </ng-template>
          </ng-select>
        </div>
        <div class="col-lg-12">
          <label for="comment" class="form-label" [tsOptionalLabel] i18n="@@DTO.ActivityDto.Comment">[i18n] Comment</label>
          <textarea id="comment" class="form-control" [readonly]="isReadOnly" formControlName="comment" rows="3"></textarea>
        </div>
        <div class="col-lg-12">
          <div class="form-check form-switch" [ngClass]="{disabled: isReadOnly}">
            <input class="form-check-input" type="checkbox" id="hidden" formControlName="hidden">
            <label class="form-label" for="hidden" i18n="@@DTO.ActivityDto.Hidden">
              [i18n] Hidden
            </label>
          </div>
        </div>
      </div>
      <hr class="text-secondary" *ngIf="form.submitted && form.invalid">
      <ts-form-validation-errors></ts-form-validation-errors>
    </form>
  </div>
  <div class="modal-footer">
    <button id="cancel" form="activityForm" type="button" class="btn btn-secondary me-3" (click)="modal.close()">
      <ng-container i18n="@@Common.Cancel">[i18n] Cancel</ng-container>
    </button>
    <button id="save" form="activityForm" type="submit" class="btn btn-primary" [disabled]="isReadOnly">
      <svg class="bi check-lg" fill="currentColor">
        <use xlink:href="assets/icons.svg#check-lg"/>
      </svg>
      <ng-container i18n="@@Common.Save">[i18n] Save</ng-container>
    </button>
  </div>
</ng-template>
