<ng-template #holidayImport let-modal>
  <div class="modal-header">
    <h4 class="m-0" i18n="@@Page.MasterData.Holidays.Import">[i18n] Import holiday</h4>
    <button type="button" class="btn modal-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <svg class="bi action-icon" fill="currentColor">
        <use xlink:href="assets/icons.svg#x-circle"/>
      </svg>
    </button>
  </div>
  <div class="modal-body">
    <form id="holidayForm" [formGroup]="holidayForm" (ngSubmit)="save()" #form="ngForm">
      <div class="row g-4">
        <div class="col-lg-12">
          <label for="formFile" class="form-label" i18n="@@DTO.HolidayImportDto.File">[i18n] iCal file</label>
          <input id="formFile" class="form-control" type="file" (change)="handleFileInput($event)" formControlName="file" ngbAutofocus>
        </div>
        <div class="col-lg-6">
          <label for="type" class="form-label" i18n="@@DTO.HolidayImportDto.Type">[i18n] Type</label>
          <ng-select id="type"
                     [selectOnTab]="true"
                     labelForId="type"
                     formControlName="type">
            <ng-option value="holiday" i18n="@@Enum.HolidayType.Holiday">[i18n] Holidays / Vacation</ng-option>
            <ng-option value="publicHoliday" i18n="@@Enum.HolidayType.PublicHoliday">[i18n] Public holiday</ng-option>
          </ng-select>
        </div>
      </div>
      <hr class="text-secondary" *ngIf="form.submitted && form.invalid">
      <ts-form-validation-errors></ts-form-validation-errors>
    </form>
  </div>
  <div class="modal-footer">
    <button id="cancel" form="holidayForm" type="button" class="btn btn-secondary me-3" (click)="modal.close()">
      <ng-container i18n="@@Common.Cancel">[i18n] Cancel</ng-container>
    </button>
    <button id="save" form="holidayForm" type="submit" class="btn btn-primary">
      <svg class="bi check-lg" fill="currentColor">
        <use xlink:href="assets/icons.svg#check-lg"/>
      </svg>
      <ng-container i18n="@@Page.MasterData.Holidays.Import">[i18n] Import</ng-container>
    </button>
  </div>
</ng-template>
