/**
 * Time Tracking API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';
import { DateTime }                                          from 'luxon';
import { Duration }                                          from 'luxon';

// @ts-ignore
import { ApplicationError } from '../model/applicationError';
// @ts-ignore
import { HolidayDto } from '../model/holidayDto';
// @ts-ignore
import { HolidayGridDto } from '../model/holidayGridDto';
// @ts-ignore
import { HolidayType } from '../model/holidayType';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface HolidayCreateRequestParams {
    /** The item to create. */
    holidayDto?: HolidayDto;
}

export interface HolidayDeleteRequestParams {
    /** The identifier. */
    id: string;
}

export interface HolidayGetRequestParams {
    /** The identifier. */
    id: string;
}

export interface HolidayGetGridFilteredRequestParams {
    /** The unique identifier of the entity. */
    timeSheetId?: string;
    /** Comment for this item. */
    timeSheetComment?: string;
    /** The related issue/ticket/... . */
    timeSheetIssue?: string;
    /** The start date. */
    timeSheetStartDate?: string;
    /** The end date. */
    timeSheetEndDate?: string;
    /** Indicates whether this item is billable. */
    timeSheetBillable?: string;
    /** The unique identifier of the entity. */
    projectId?: string;
    /** The display name of the project. */
    projectTitle?: string;
    /** Comment for this item. */
    projectComment?: string;
    /** Indicates whether this item is hidden. */
    projectHidden?: string;
    /** The unique identifier of the entity. */
    customerId?: string;
    /** The display name of the customer. */
    customerTitle?: string;
    /** The customer number. */
    customerNumber?: string;
    /** The department. */
    customerDepartment?: string;
    /** The name of the company. */
    customerCompanyName?: string;
    /** The name of the contact. */
    customerContactName?: string;
    /** The hourly rate. */
    customerHourlyRate?: string;
    /** The street. */
    customerStreet?: string;
    /** The zip code. */
    customerZipCode?: string;
    /** The city. */
    customerCity?: string;
    /** The country. */
    customerCountry?: string;
    /** Comment for this item. */
    customerComment?: string;
    /** Indicates whether this item is hidden. */
    customerHidden?: string;
    /** The display name for this item. */
    activityId?: string;
    /** The title for this item. */
    activityTitle?: string;
    /** Comment for this item. */
    activityComment?: string;
    /** Indicates whether this item is hidden. */
    activityHidden?: string;
    /** The unique identifier of the entity. */
    orderId?: string;
    /** The display name of the order. */
    orderTitle?: string;
    /** Description of this item. */
    orderDescription?: string;
    /** The order number. */
    orderNumber?: string;
    /** The start date. */
    orderStartDate?: string;
    /** The due date. */
    orderDueDate?: string;
    /** The hourly rate. */
    orderHourlyRate?: string;
    /** The available budget. */
    orderBudget?: string;
    /** Comment for this item. */
    orderComment?: string;
    /** Indicates whether this item is hidden. */
    orderHidden?: string;
    /** The unique identifier of the entity. */
    holidayId?: string;
    /** The display name of the holiday. */
    holidayTitle?: string;
    /** The start date. */
    holidayStartDate?: string;
    /** The end date. */
    holidayEndDate?: string;
    /** The reason for holiday. */
    holidayType?: string;
    /** The unique identifier of the entity. */
    userId?: string;
    /** The username. */
    userUsername?: string;
    /** The person\&#39;s first name. */
    userFirstName?: string;
    /** The person\&#39;s last name. */
    userLastName?: string;
    /** The user\&#39;s email. */
    userEmail?: string;
    /** Indicates whether this user is enabled. */
    userEnabled?: string;
}

export interface HolidayGetGridItemRequestParams {
    /** The identifier. */
    id: string;
}

export interface HolidayImportRequestParams {
    /** The holiday type. */
    type: HolidayType;
    file: Blob;
}

export interface HolidayUpdateRequestParams {
    /** The item to update. */
    holidayDto?: HolidayDto;
}


@Injectable({
  providedIn: 'root'
})
export class HolidayService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof DateTime === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof DateTime) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as DateTime).toISO()!);
                } else {
                   throw Error("key may not be null if value is DateTime");
                }
            } else if (value instanceof Duration) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Duration).toISOTime()!);
                } else {
                   throw Error("key may not be null if value is Duration");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Creates the specified item.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public create(requestParameters: HolidayCreateRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json', context?: HttpContext}): Observable<HolidayDto>;
    public create(requestParameters: HolidayCreateRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<HolidayDto>>;
    public create(requestParameters: HolidayCreateRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<HolidayDto>>;
    public create(requestParameters: HolidayCreateRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const holidayDto = requestParameters.holidayDto;

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/Holiday/Create`;
        return this.httpClient.request<HolidayDto>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: holidayDto,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes the item specified by id.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public delete(requestParameters: HolidayDeleteRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json', context?: HttpContext}): Observable<number>;
    public delete(requestParameters: HolidayDeleteRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<number>>;
    public delete(requestParameters: HolidayDeleteRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<number>>;
    public delete(requestParameters: HolidayDeleteRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling .');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/Holiday/Delete/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<number>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets the item specified by id.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public get(requestParameters: HolidayGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json', context?: HttpContext}): Observable<HolidayDto>;
    public get(requestParameters: HolidayGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<HolidayDto>>;
    public get(requestParameters: HolidayGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<HolidayDto>>;
    public get(requestParameters: HolidayGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling .');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/Holiday/Get/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<HolidayDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets items as filtered grid rows.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getGridFiltered(requestParameters: HolidayGetGridFilteredRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json', context?: HttpContext}): Observable<Array<HolidayGridDto>>;
    public getGridFiltered(requestParameters: HolidayGetGridFilteredRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Array<HolidayGridDto>>>;
    public getGridFiltered(requestParameters: HolidayGetGridFilteredRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Array<HolidayGridDto>>>;
    public getGridFiltered(requestParameters: HolidayGetGridFilteredRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const timeSheetId = requestParameters.timeSheetId;
        const timeSheetComment = requestParameters.timeSheetComment;
        const timeSheetIssue = requestParameters.timeSheetIssue;
        const timeSheetStartDate = requestParameters.timeSheetStartDate;
        const timeSheetEndDate = requestParameters.timeSheetEndDate;
        const timeSheetBillable = requestParameters.timeSheetBillable;
        const projectId = requestParameters.projectId;
        const projectTitle = requestParameters.projectTitle;
        const projectComment = requestParameters.projectComment;
        const projectHidden = requestParameters.projectHidden;
        const customerId = requestParameters.customerId;
        const customerTitle = requestParameters.customerTitle;
        const customerNumber = requestParameters.customerNumber;
        const customerDepartment = requestParameters.customerDepartment;
        const customerCompanyName = requestParameters.customerCompanyName;
        const customerContactName = requestParameters.customerContactName;
        const customerHourlyRate = requestParameters.customerHourlyRate;
        const customerStreet = requestParameters.customerStreet;
        const customerZipCode = requestParameters.customerZipCode;
        const customerCity = requestParameters.customerCity;
        const customerCountry = requestParameters.customerCountry;
        const customerComment = requestParameters.customerComment;
        const customerHidden = requestParameters.customerHidden;
        const activityId = requestParameters.activityId;
        const activityTitle = requestParameters.activityTitle;
        const activityComment = requestParameters.activityComment;
        const activityHidden = requestParameters.activityHidden;
        const orderId = requestParameters.orderId;
        const orderTitle = requestParameters.orderTitle;
        const orderDescription = requestParameters.orderDescription;
        const orderNumber = requestParameters.orderNumber;
        const orderStartDate = requestParameters.orderStartDate;
        const orderDueDate = requestParameters.orderDueDate;
        const orderHourlyRate = requestParameters.orderHourlyRate;
        const orderBudget = requestParameters.orderBudget;
        const orderComment = requestParameters.orderComment;
        const orderHidden = requestParameters.orderHidden;
        const holidayId = requestParameters.holidayId;
        const holidayTitle = requestParameters.holidayTitle;
        const holidayStartDate = requestParameters.holidayStartDate;
        const holidayEndDate = requestParameters.holidayEndDate;
        const holidayType = requestParameters.holidayType;
        const userId = requestParameters.userId;
        const userUsername = requestParameters.userUsername;
        const userFirstName = requestParameters.userFirstName;
        const userLastName = requestParameters.userLastName;
        const userEmail = requestParameters.userEmail;
        const userEnabled = requestParameters.userEnabled;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (timeSheetId !== undefined && timeSheetId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>timeSheetId, 'timeSheetId');
        }
        if (timeSheetComment !== undefined && timeSheetComment !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>timeSheetComment, 'timeSheetComment');
        }
        if (timeSheetIssue !== undefined && timeSheetIssue !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>timeSheetIssue, 'timeSheetIssue');
        }
        if (timeSheetStartDate !== undefined && timeSheetStartDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>timeSheetStartDate, 'timeSheetStartDate');
        }
        if (timeSheetEndDate !== undefined && timeSheetEndDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>timeSheetEndDate, 'timeSheetEndDate');
        }
        if (timeSheetBillable !== undefined && timeSheetBillable !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>timeSheetBillable, 'timeSheetBillable');
        }
        if (projectId !== undefined && projectId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>projectId, 'projectId');
        }
        if (projectTitle !== undefined && projectTitle !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>projectTitle, 'projectTitle');
        }
        if (projectComment !== undefined && projectComment !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>projectComment, 'projectComment');
        }
        if (projectHidden !== undefined && projectHidden !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>projectHidden, 'projectHidden');
        }
        if (customerId !== undefined && customerId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>customerId, 'customerId');
        }
        if (customerTitle !== undefined && customerTitle !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>customerTitle, 'customerTitle');
        }
        if (customerNumber !== undefined && customerNumber !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>customerNumber, 'customerNumber');
        }
        if (customerDepartment !== undefined && customerDepartment !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>customerDepartment, 'customerDepartment');
        }
        if (customerCompanyName !== undefined && customerCompanyName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>customerCompanyName, 'customerCompanyName');
        }
        if (customerContactName !== undefined && customerContactName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>customerContactName, 'customerContactName');
        }
        if (customerHourlyRate !== undefined && customerHourlyRate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>customerHourlyRate, 'customerHourlyRate');
        }
        if (customerStreet !== undefined && customerStreet !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>customerStreet, 'customerStreet');
        }
        if (customerZipCode !== undefined && customerZipCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>customerZipCode, 'customerZipCode');
        }
        if (customerCity !== undefined && customerCity !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>customerCity, 'customerCity');
        }
        if (customerCountry !== undefined && customerCountry !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>customerCountry, 'customerCountry');
        }
        if (customerComment !== undefined && customerComment !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>customerComment, 'customerComment');
        }
        if (customerHidden !== undefined && customerHidden !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>customerHidden, 'customerHidden');
        }
        if (activityId !== undefined && activityId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>activityId, 'activityId');
        }
        if (activityTitle !== undefined && activityTitle !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>activityTitle, 'activityTitle');
        }
        if (activityComment !== undefined && activityComment !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>activityComment, 'activityComment');
        }
        if (activityHidden !== undefined && activityHidden !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>activityHidden, 'activityHidden');
        }
        if (orderId !== undefined && orderId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>orderId, 'orderId');
        }
        if (orderTitle !== undefined && orderTitle !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>orderTitle, 'orderTitle');
        }
        if (orderDescription !== undefined && orderDescription !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>orderDescription, 'orderDescription');
        }
        if (orderNumber !== undefined && orderNumber !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>orderNumber, 'orderNumber');
        }
        if (orderStartDate !== undefined && orderStartDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>orderStartDate, 'orderStartDate');
        }
        if (orderDueDate !== undefined && orderDueDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>orderDueDate, 'orderDueDate');
        }
        if (orderHourlyRate !== undefined && orderHourlyRate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>orderHourlyRate, 'orderHourlyRate');
        }
        if (orderBudget !== undefined && orderBudget !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>orderBudget, 'orderBudget');
        }
        if (orderComment !== undefined && orderComment !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>orderComment, 'orderComment');
        }
        if (orderHidden !== undefined && orderHidden !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>orderHidden, 'orderHidden');
        }
        if (holidayId !== undefined && holidayId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>holidayId, 'holidayId');
        }
        if (holidayTitle !== undefined && holidayTitle !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>holidayTitle, 'holidayTitle');
        }
        if (holidayStartDate !== undefined && holidayStartDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>holidayStartDate, 'holidayStartDate');
        }
        if (holidayEndDate !== undefined && holidayEndDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>holidayEndDate, 'holidayEndDate');
        }
        if (holidayType !== undefined && holidayType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>holidayType, 'holidayType');
        }
        if (userId !== undefined && userId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>userId, 'userId');
        }
        if (userUsername !== undefined && userUsername !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>userUsername, 'userUsername');
        }
        if (userFirstName !== undefined && userFirstName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>userFirstName, 'userFirstName');
        }
        if (userLastName !== undefined && userLastName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>userLastName, 'userLastName');
        }
        if (userEmail !== undefined && userEmail !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>userEmail, 'userEmail');
        }
        if (userEnabled !== undefined && userEnabled !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>userEnabled, 'userEnabled');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/Holiday/GetGridFiltered`;
        return this.httpClient.request<Array<HolidayGridDto>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a single grid row.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getGridItem(requestParameters: HolidayGetGridItemRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json', context?: HttpContext}): Observable<HolidayGridDto>;
    public getGridItem(requestParameters: HolidayGetGridItemRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<HolidayGridDto>>;
    public getGridItem(requestParameters: HolidayGetGridItemRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<HolidayGridDto>>;
    public getGridItem(requestParameters: HolidayGetGridItemRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling .');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/Holiday/GetGridItem/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<HolidayGridDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Imports holidays/public holidays from iCal file.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public import(requestParameters: HolidayImportRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public import(requestParameters: HolidayImportRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public import(requestParameters: HolidayImportRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public import(requestParameters: HolidayImportRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        const type = requestParameters.type;
        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling .');
        }
        const file = requestParameters.file;
        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling .');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (type !== undefined && type !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>type, 'type');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (file !== undefined) {
            localVarFormParams = localVarFormParams.append('file', <any>file) as any || localVarFormParams;
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/Holiday/Import`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates the specified item.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public update(requestParameters: HolidayUpdateRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json', context?: HttpContext}): Observable<HolidayDto>;
    public update(requestParameters: HolidayUpdateRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<HolidayDto>>;
    public update(requestParameters: HolidayUpdateRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<HolidayDto>>;
    public update(requestParameters: HolidayUpdateRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        const holidayDto = requestParameters.holidayDto;

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/Holiday/Update`;
        return this.httpClient.request<HolidayDto>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: holidayDto,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
