<div *ngIf="columns" [class]="configuration.cssWrapper">
  <table [class]="configuration.cssTable">
    <thead>
    <ng-container *ngTemplateOutlet="configuration.headRowTemplate ? configuration.headRowTemplate : defaultHeadRowTemplate; context: getHeaderRowContext()"></ng-container>
    </thead>
    <tbody>
    <ng-container *ngTemplateOutlet="configuration.filterRowTemplate ? configuration.filterRowTemplate : defaultFilterRowTemplate; context: getHeaderRowContext()"></ng-container>
    <ng-container *ngTemplateOutlet="configuration.dataRowTemplate ? configuration.dataRowTemplate : defaultDataRowTemplate; context: getHeaderRowContext()"></ng-container>
    </tbody>
    <tfoot *ngIf="configuration.footRowTemplate || getFooterRequired()">
    <ng-container *ngTemplateOutlet="configuration.footRowTemplate ? configuration.footRowTemplate:defaultFooterRowTemplate; context: getHeaderRowContext()"></ng-container>
    </tfoot>
  </table>
</div>

<ng-template #defaultHeadRowTemplate let-columns="columns">
  <tr [class]="getCssHeadRow()">
    <th *ngFor="let column of columns;"
        [ngClass]="[configuration.cssHeadCell, getCssHeadCell(column), getCssSortEnabled(column)]"
        [style]="getColumnWidth(column)"
        (click)="applySortOrder(column, $event)"
        scope="col">
      <ng-container *ngTemplateOutlet="column.headCellTemplate ? column.headCellTemplate : defaultHeadCellTemplate; context: getHeaderCellContext(column)"></ng-container>
    </th>
  </tr>
</ng-template>

<ng-template #defaultFilterRowTemplate let-columns="columns">
  <tr *ngIf="configuration.filterRow">
    <td *ngFor="let column of columns;"
        [ngClass]="[configuration.cssFilterCell, getCssFilterCell(column)]"
        [style]="getColumnWidth(column)">
      <ng-container *ngIf="column.filterable !== 'no'">
        <ng-container *ngTemplateOutlet="column.filterCellTemplate ? column.filterCellTemplate : defaultFilterCellTemplate; context: getFilterCellContext(column)"></ng-container>
      </ng-container>
    </td>
  </tr>
</ng-template>

<ng-template #defaultDataRowTemplate let-columns="columns">
  <tr *ngFor="let row of filteredRows; index as index, count as count; first as first; last as last; even as even; odd as odd; trackBy: configuration.trackBy;"
      [class]="getCssDataRow(row)">
    <td *ngFor="let column of columns;"
        [ngClass]="[configuration.cssDataCell, getCssDataCell(row, column)]"
        [style]="getColumnWidth(column)"
        (click)="onDataCellClick(row, column, $event)">
      <ng-container *ngTemplateOutlet="column.dataCellTemplate ? column.dataCellTemplate : defaultDataCellTemplate; context: getDataCellContext(row, column, index, count, first, last,even, odd)"></ng-container>
    </td>
  </tr>
</ng-template>

<ng-template #defaultFooterRowTemplate let-columns="columns">
  <tr [class]="getCssFooterRow()">
    <td *ngFor="let column of columns;"
        [ngClass]="[configuration.cssFooterCell, getCssFooterCell(column)]"
        [style]="getColumnWidth(column)">
      <ng-container *ngTemplateOutlet="column.footerCellTemplate ? column.footerCellTemplate : defaultFooterCellTemplate; context: getHeaderCellContext(column)"></ng-container>
    </td>
  </tr>
</ng-template>

<ng-template #defaultHeadCellTemplate let-column="column">
  <span>{{column.title}}</span>
  <span [class]="getCssSortOrder(column)">{{getGlyphSortOrder(column)}}</span>
</ng-template>

<ng-template #defaultFilterCellTemplate let-column="column" let-filters="filters" let-applyFilter="applyFilter">
  <!--suppress HtmlFormInputWithoutLabel -->
  <input
    [attr.aria-label]="column.title"
    [(ngModel)]="filters[column.prop]"
    (ngModelChange)="applyFilter()">
</ng-template>

<ng-template #defaultDataCellTemplate let-row="row" let-column="column">
  {{getCellValue(row, column)}}
</ng-template>

<ng-template #defaultFooterCellTemplate let-row="row" let-column="column">
  {{getFooterValue(column)}}
</ng-template>
