<div class="row">
  <!-- Report preview -->
  <div class="col-12 col-md-8 col-xl-9 order-last order-md-first px-4">
    <ng-container *ngTemplateOutlet="reportPreview"></ng-container>
  </div>
  <div class="col-12 col-md-4 col-xl-3 order-first order-md-last px-4 px-md-2 ps-md-2 ps-lg-5 pb-5 pb-md-0 filter">
    <!-- Filter -->
    <ts-filter filterId="filterTimeSheet"
               [filters]="filters">
    </ts-filter>
  </div>
</div>

<ng-template #reportPreview>
  <div class="d-flex justify-content-between align-items-start mb-2">
    <h4>{{title}}</h4>
    <a class="btn btn-primary" [ngClass]="{'disabled':!downloadLink || !totalReportPages}" [href]="downloadLink">
      <ng-container i18n="@@Page.Report.Activity.Download">[i18n] Download</ng-container>
    </a>
  </div>

  <ng-container *ngIf="previewImages; else pageSkeleton">
    <div class="row mt-4">
      <div class="col-4" *ngFor="let previewImage of previewImages">
        <img [src]="previewImage" alt="Preview" i18n-alt="@@Page.Report.Activity.Preview" class="border w-100">
      </div>
    </div>

    <div class="row">
      <div class="col text-center text-secondary mt-3">
        <ng-container *ngIf="totalReportPages" i18n="@@Page.Report.Activity.PreviewPages">
          [i18n] {{previewImages.length //i18n(ph="PAGES")}} of {{totalReportPages //i18n(ph="TOTAL")}} pages
        </ng-container>
        <ng-container *ngIf="!totalReportPages" i18n="@@Page.Report.Activity.NoActivityReports">
          [i18n] No activity reports available
        </ng-container>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #pageSkeleton>
  <div class="row mt-4">
    <div class="col-4">
      <div class="border image-skeleton placeholder-glow">

        <div class="row g-0 p-3">
          <span class="col-6 placeholder bg-secondary" style="height: min-content"></span>
          <span class="col-4 offset-2 placeholder bg-secondary" style="aspect-ratio: 1"></span>
        </div>
        <div class="row g-0 p-3">
          <span class="col-6 placeholder bg-secondary"></span>
          <span class="col-6 placeholder bg-transparent"></span>

          <span class="col-12 placeholder placeholder-sm mt-3 bg-secondary"></span>
          <span class="col-12 placeholder placeholder-sm mt-1 bg-secondary"></span>
          <span class="col-12 placeholder placeholder-sm mt-1 bg-secondary"></span>
        </div>
      </div>
    </div>
  </div>
</ng-template>


<router-outlet></router-outlet>
