<header class="sticky-top">
  <nav class="container-fluid px-0 ">
    <ts-page-navigation></ts-page-navigation>
  </nav>
</header>

<aside>
  <ts-timesheet-header></ts-timesheet-header>
</aside>

<main role="main" class="container pb-3 pt-5">
  <router-outlet></router-outlet>
</main>

<footer class="d-flex flex-column bg-light">
  <hr class="m-0">
  <div class="row mx-0 h-100 align-items-center">
    <div class="col p-0 text-center">
      <ts-page-footer></ts-page-footer>
    </div>
  </div>
</footer>
