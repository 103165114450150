import { Component } from '@angular/core';

@Component({
  selector: 'ts-root',
  templateUrl: './timetracking.component.html',
  styleUrls: ['./timetracking.component.scss']
})
export class TimeTrackingComponent {
  title = 'TimeTracking';
}
