<!-- Header -->
<div class="d-flex align-items-baseline mb-4" (click)="collapseFilters()">
  <h4 class="col-auto" i18n="@@Component.Filter.Title">[i18n] Settings & Filter</h4>
  <div class="ms-auto d-md-none" style="user-select: none">
    <svg class="bi text-secondary me-2" fill="currentColor">
      <use *ngIf="filterCollapsed" xlink:href="assets/icons.svg#chevron-down"/>
      <use *ngIf="!filterCollapsed" xlink:href="assets/icons.svg#chevron-up"/>
    </svg>
  </div>
</div>

<form *ngIf="filterForm !== undefined" id="filterForm" [formGroup]="filterForm" #collapse="ngbCollapse" [(ngbCollapse)]="filterCollapsed">

  <ng-container *ngIf="filterTemplates">
    <!-- Standard filter -->
    <div class="row g-3">
      <ng-container *ngFor="let filter of visibleFilters">
        <ng-container *ngTemplateOutlet="filterTemplates[filter.name]"></ng-container>
      </ng-container>
    </div>

    <!-- Extended filter -->
    <div id="extendedFilter" #collapse="ngbCollapse" [(ngbCollapse)]="extendedFilterCollapsed">
      <div class="row g-3 my-0">
        <ng-container *ngFor="let filter of hiddenFilters">
          <ng-container *ngTemplateOutlet="filterTemplates[filter.name]"></ng-container>
        </ng-container>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-auto">
        <button *ngIf="hiddenFilters && hiddenFilters.length"
                id="extended-filter-toggler"
                type="button"
                class="btn btn-secondary add-filter"
                (click)="collapse.toggle()"
                aria-controls="extendedFilter"
                [attr.aria-expanded]="!extendedFilterCollapsed">
          <ng-container *ngIf="extendedFilterCollapsed">
            <svg class="bi text-secondary me-2" fill="currentColor">
              <use xlink:href="assets/icons.svg#plus-square-dotted"/>
            </svg>
            <span class="text-expand" i18n="@@Component.Filter.Add">[i18n] Add filter</span>
          </ng-container>
          <ng-container *ngIf="!extendedFilterCollapsed">
            <svg class="bi text-secondary me-2" fill="currentColor">
              <use xlink:href="assets/icons.svg#check-lg"/>
            </svg>
            <span class="text-expand" i18n="@@Component.Filter.Apply">[i18n] Apply filter</span>
          </ng-container>
        </button>
      </div>
      <div class="row justify-content-center">
        <div class="col-auto">
          <button *ngIf="isFiltered$ | async"
                  (click)="resetFilterForm()"
                  type="button"
                  class="btn text-primary fw-normal reset-filter">
            <svg class="bi text-secondary me-2" fill="currentColor">
              <use xlink:href="assets/icons.svg#arrow-clockwise"/>
            </svg>
            <span class="text-expand" i18n="@@Component.Filter.Reset">[i18n] Reset filter</span>
          </button>
        </div>
      </div>
    </div>
  </ng-container>


  <!-- timeSheetStartDate -->
  <ng-template #timeSheetStartDate>
    <div class="col-12">
      <label class="form-label" i18n="@@Component.Filter.LimitPeriod">[i18n] Limit period</label>
      <label class="form-label visually-hidden" for="timeSheetStartDate" i18n="@@Component.Filter.From">[i18n] From</label>
      <ts-date-month-picker
        id="timeSheetStartDate"
        placeholderMonth="[i18n] from"
        i18n-placeholderMonth="@@Component.Filter.From"
        placeholderDate="[i18n] Start"
        i18n-placeholderDate="@@Component.Filter.Start"
        formControlName="timeSheetStartDate"
        [clearBtn]="isResettable('timeSheetStartDate')">
      </ts-date-month-picker>
    </div>
  </ng-template>

  <!-- timeSheetEndDate -->
  <ng-template #timeSheetEndDate>
    <div class="col-12">
      <label class="form-label visually-hidden" for="timeSheetEndDate" i18n="@@Component.Filter.To">[i18n] To</label>
      <ts-date-month-picker
        id="timeSheetEndDate"
        placeholderMonth="[i18n] to"
        i18n-placeholderMonth="@@Component.Filter.To"
        placeholderDate="[i18n] End"
        i18n-placeholderDate="@@Component.Filter.End"
        formControlName="timeSheetEndDate"
        relativeAnchor="end"
        [clearBtn]="isResettable('timeSheetEndDate')">
      </ts-date-month-picker>
    </div>
  </ng-template>

  <!-- orderStartDate -->
  <ng-template #orderStartDate>
    <div class="col-12">
      <label class="form-label" i18n="@@Component.Filter.LimitPeriod">[i18n] Limit period</label>
      <label class="form-label visually-hidden" for="orderStartDate" i18n="@@Component.Filter.From">[i18n] From</label>
      <ts-date-month-picker
        id="orderStartDate"
        placeholderMonth="[i18n] from"
        i18n-placeholderMonth="@@Component.Filter.From"
        placeholderDate="[i18n] Start"
        i18n-placeholderDate="@@Component.Filter.Start"
        formControlName="orderStartDate"
        [clearBtn]="isResettable('orderStartDate')">
      </ts-date-month-picker>
    </div>
  </ng-template>

  <!-- orderDueDate -->
  <ng-template #orderDueDate>
    <div class="col-12">
      <label class="form-label visually-hidden" for="orderDueDate" i18n="@@Component.Filter.To">[i18n] To</label>
      <ts-date-month-picker
        id="orderDueDate"
        placeholderMonth="[i18n] to"
        i18n-placeholderMonth="@@Component.Filter.To"
        placeholderDate="[i18n] End"
        i18n-placeholderDate="@@Component.Filter.End"
        formControlName="orderDueDate"
        relativeAnchor="end"
        [clearBtn]="isResettable('orderDueDate')">
      </ts-date-month-picker>
    </div>
  </ng-template>

  <!-- holidayStartDate -->
  <ng-template #holidayStartDate>
    <div class="col-12">
      <label class="form-label" i18n="@@Component.Filter.LimitPeriod">[i18n] Limit period</label>
      <label class="form-label visually-hidden" for="holidayStartDate" i18n="@@Component.Filter.From">[i18n] From</label>
      <ts-date-month-picker
        id="holidayStartDate"
        placeholderMonth="[i18n] from"
        i18n-placeholderMonth="@@Component.Filter.From"
        placeholderDate="[i18n] Start"
        i18n-placeholderDate="@@Component.Filter.Start"
        formControlName="holidayStartDate"
        [clearBtn]="isResettable('holidayStartDate')">
      </ts-date-month-picker>
    </div>
  </ng-template>

  <!-- holidayEndDate -->
  <ng-template #holidayEndDate>
    <div class="col-12">
      <label class="form-label visually-hidden" for="holidayEndDate" i18n="@@Component.Filter.To">[i18n] To</label>
      <ts-date-month-picker
        id="holidayEndDate"
        placeholderMonth="[i18n] to"
        i18n-placeholderMonth="@@Component.Filter.To"
        placeholderDate="[i18n] End"
        i18n-placeholderDate="@@Component.Filter.End"
        formControlName="holidayEndDate"
        relativeAnchor="end"
        [clearBtn]="isResettable('holidayEndDate')">
      </ts-date-month-picker>
    </div>
  </ng-template>

  <!-- customerId -->
  <ng-template #customerId>
    <div class="col-12">
      <!--suppress XmlInvalidId -->
      <label for="customerTitle" class="form-label" i18n="@@Component.Filter.Customer">[i18n] Customer</label>
      <ng-select [items]="customers$ | async"
                 [selectOnTab]="true"
                 [multiple]="true"
                 placeholder="[i18n] Customer"
                 i18n-placeholder="@@Component.Filter.Customer"
                 bindValue="id"
                 bindLabel="value"
                 labelForId="customerTitle"
                 formControlName="customerId">
        <ng-template ng-option-tmp let-item="item">
          <span [ngClass]="{'text-secondary': item.hidden}">{{item.value}}</span>
        </ng-template>
      </ng-select>
    </div>
  </ng-template>

  <!-- projectId -->
  <ng-template #projectId>
    <div class="col-12">
      <!--suppress XmlInvalidId -->
      <label for="projectTitle" class="form-label" i18n="@@Component.Filter.Project">[i18n] Project</label>
      <ng-select [items]="projects$ | async"
                 [selectOnTab]="true"
                 [multiple]="true"
                 bindValue="id"
                 bindLabel="value"
                 placeholder="[i18n] Project"
                 i18n-placeholder="@@Component.Filter.Project"
                 labelForId="projectTitle"
                 formControlName="projectId">
        <ng-template ng-option-tmp let-item="item">
          <span [ngClass]="{'text-secondary': item.hidden}">{{item.value}}</span>
        </ng-template>
      </ng-select>
    </div>
  </ng-template>

  <!-- activityId -->
  <ng-template #activityId>
    <div class="col-12">
      <!--suppress XmlInvalidId -->
      <label for="activityTitle" class="form-label" i18n="@@Component.Filter.Activity">[i18n] Activity</label>
      <ng-select [items]="activities$ | async"
                 [selectOnTab]="true"
                 [multiple]="true"
                 bindValue="id"
                 bindLabel="value"
                 placeholder="[i18n] Activity"
                 i18n-placeholder="@@Component.Filter.Activity"
                 labelForId="activityTitle"
                 formControlName="activityId">
        <ng-template ng-option-tmp let-item="item">
          <span [ngClass]="{'text-secondary': item.hidden}">{{item.value}}</span>
        </ng-template>
      </ng-select>
    </div>
  </ng-template>

  <!-- orderId -->
  <ng-template #orderId>
    <div class="col-12">
      <!--suppress XmlInvalidId -->
      <label for="orderTitle" class="form-label" i18n="@@Component.Filter.Order">[i18n] Order</label>
      <ng-select [items]="orders$ | async"
                 [selectOnTab]="true"
                 [multiple]="true"
                 placeholder="[i18n] Order"
                 i18n-placeholder="@@Component.Filter.Order"
                 bindValue="id"
                 bindLabel="value"
                 labelForId="orderTitle"
                 formControlName="orderId">
        <ng-template ng-option-tmp let-item="item">
          <span [ngClass]="{'text-secondary': item.hidden}">{{item.value}}</span>
        </ng-template>
      </ng-select>
    </div>
  </ng-template>

  <!-- timeSheetIssue -->
  <ng-template #timeSheetIssue>
    <div class="col-12">
      <label for="timeSheetIssue" class="form-label" i18n="@@Component.Filter.Issue">[i18n] Issue</label>
      <ts-clearable-input id="timeSheetIssue"
                          placeholder="[i18n] Issue"
                          i18n-placeholder="@@Component.Filter.Issue"
                          formControlName="timeSheetIssue">
      </ts-clearable-input>
    </div>
  </ng-template>

  <!-- customerNumber -->
  <ng-template #customerNumber>
    <div class="col-12">
      <label for="customerNumber" class="form-label" i18n="@@Component.Filter.CustomerNumber">[i18n] Number</label>
      <ts-clearable-input id="customerNumber"
                          placeholder="[i18n] Number"
                          i18n-placeholder="@@Component.Filter.CustomerNumber"
                          formControlName="customerNumber">
      </ts-clearable-input>
    </div>
  </ng-template>

  <!-- customerCompanyName -->
  <ng-template #customerCompanyName>
    <div class="col-12">
      <label for="customerCompanyName" class="form-label" i18n="@@Component.Filter.CustomerCompanyName">[i18n] Company</label>
      <ts-clearable-input id="customerCompanyName"
                          placeholder="[i18n] Company"
                          i18n-placeholder="@@Component.Filter.CustomerCompanyName"
                          formControlName="customerCompanyName">
      </ts-clearable-input>
    </div>
  </ng-template>

  <!-- timeSheetComment -->
  <ng-template #timeSheetComment>
    <div class="col-12">
      <label for="timeSheetComment" class="form-label" i18n="@@Component.Filter.Comment">[i18n] Comment</label>
      <ts-clearable-input id="timeSheetComment"
                          placeholder="[i18n] Comment"
                          i18n-placeholder="@@Component.Filter.Comment"
                          formControlName="timeSheetComment">
      </ts-clearable-input>
    </div>
  </ng-template>

  <!-- holidayTitle -->
  <ng-template #holidayTitle>
    <div class="col-12">
      <label for="holidayTitle" class="form-label" i18n="@@Component.Filter.HolidayTitle">[i18n] Title</label>
      <ts-clearable-input id="holidayTitle"
                          placeholder="[i18n] Title"
                          i18n-placeholder="@@Component.Filter.HolidayTitle"
                          formControlName="holidayTitle">
      </ts-clearable-input>
    </div>
  </ng-template>

  <!-- userId -->
  <ng-template #userId>
    <div class="col-12">
      <!--suppress XmlInvalidId -->
      <label for="userUsername" class="form-label" i18n="@@Component.Filter.Username">[i18n] Username</label>
      <ng-select [items]="users$ | async"
                 [selectOnTab]="true"
                 [multiple]="true"
                 placeholder="[i18n] Username"
                 i18n-placeholder="@@Component.Filter.Username"
                 bindValue="id"
                 bindLabel="value"
                 labelForId="userUsername"
                 formControlName="userId">
        <ng-template ng-option-tmp let-item="item">
          <span [ngClass]="{'text-secondary': item.hidden}">{{item.value}}</span>
        </ng-template>
      </ng-select>
    </div>
  </ng-template>

  <!-- userUsername -->
  <ng-template #userUsername>
    <div class="col-12">
      <label for="userUsername" class="form-label" i18n="@@Component.Filter.Username">[i18n] Username</label>
      <ts-clearable-input id="userUsername"
                          placeholder="[i18n] Username"
                          i18n-placeholder="@@Component.Filter.Username"
                          formControlName="userUsername">
      </ts-clearable-input>
    </div>
  </ng-template>

  <!-- userFirstName -->
  <ng-template #userFirstName>
    <div class="col-12">
      <label for="userFirstName" class="form-label" i18n="@@Component.Filter.FirstName">[i18n] First name</label>
      <ts-clearable-input id="userFirstName"
                          placeholder="[i18n] First name"
                          i18n-placeholder="@@Component.Filter.FirstName"
                          formControlName="userFirstName">
      </ts-clearable-input>
    </div>
  </ng-template>

  <!-- userLastName -->
  <ng-template #userLastName>
    <div class="col-12">
      <label for="userLastName" class="form-label" i18n="@@Component.Filter.LastName">[i18n] Last name</label>
      <ts-clearable-input id="userLastName"
                          placeholder="[i18n] Last name"
                          i18n-placeholder="@@Component.Filter.LastName"
                          formControlName="userLastName">
      </ts-clearable-input>
    </div>
  </ng-template>

  <!-- userEmail -->
  <ng-template #userEmail>
    <div class="col-12">
      <label for="userEmail" class="form-label" i18n="@@Component.Filter.Email">[i18n] Email</label>
      <ts-clearable-input id="userEmail"
                          placeholder="[i18n] Email"
                          i18n-placeholder="@@Component.Filter.Email"
                          formControlName="userEmail">
      </ts-clearable-input>
    </div>
  </ng-template>

  <!-- activityHidden -->
  <ng-template #activityHidden>
    <div class="col-12">
      <!--suppress XmlInvalidId -->
      <label for="activityHidden" class="form-label" i18n="@@Component.Filter.Hidden">[i18n] Hidden</label>
      <ng-select [selectOnTab]="true"
                 placeholder="[i18n] Hidden"
                 i18n-placeholder="@@Component.Filter.Hidden"
                 labelForId="activityHidden"
                 formControlName="activityHidden">
        <ng-option [value]="true" i18n="@@Common.Yes">[i18n] Yes</ng-option>
        <ng-option [value]="false" i18n="@@Common.No">[i18n] No</ng-option>
      </ng-select>
    </div>
  </ng-template>

  <!-- customerHidden -->
  <ng-template #customerHidden>
    <div class="col-12">
      <!--suppress XmlInvalidId -->
      <label for="customerHidden" class="form-label" i18n="@@Component.Filter.Hidden">[i18n] Hidden</label>
      <ng-select [selectOnTab]="true"
                 placeholder="[i18n] Hidden"
                 i18n-placeholder="@@Component.Filter.Hidden"
                 labelForId="customerHidden"
                 formControlName="customerHidden">
        <ng-option [value]="true" i18n="@@Common.Yes">[i18n] Yes</ng-option>
        <ng-option [value]="false" i18n="@@Common.No">[i18n] No</ng-option>
      </ng-select>
    </div>
  </ng-template>

  <!-- projectHidden -->
  <ng-template #projectHidden>
    <div class="col-12">
      <!--suppress XmlInvalidId -->
      <label for="projectHidden" class="form-label" i18n="@@Component.Filter.Hidden">[i18n] Hidden</label>
      <ng-select [selectOnTab]="true"
                 placeholder="[i18n] Hidden"
                 i18n-placeholder="@@Component.Filter.Hidden"
                 labelForId="projectHidden"
                 formControlName="projectHidden">
        <ng-option [value]="true" i18n="@@Common.Yes">[i18n] Yes</ng-option>
        <ng-option [value]="false" i18n="@@Common.No">[i18n] No</ng-option>
      </ng-select>
    </div>
  </ng-template>

  <!-- holidayType -->
  <ng-template #holidayType>
    <div class="col-12">
      <!--suppress XmlInvalidId -->
      <label for="holidayType" class="form-label" i18n="@@Component.Filter.HolidayType">[i18n] Type</label>
      <ng-select [selectOnTab]="true"
                 placeholder="[i18n] Type"
                 i18n-placeholder="@@Component.Filter.HolidayType"
                 labelForId="holidayType"
                 formControlName="holidayType">
        <ng-option value="Holiday" i18n="@@Enum.HolidayType.Holiday">[i18n] Holidays / Vacation</ng-option>
        <ng-option value="PublicHoliday" i18n="@@Enum.HolidayType.PublicHoliday">[i18n] Public holiday</ng-option>
      </ng-select>
    </div>
  </ng-template>

  <!-- timeSheetBillable -->
  <ng-template #timeSheetBillable>
    <div class="col-12">
      <!--suppress XmlInvalidId -->
      <label for="timeSheetBillable" class="form-label" i18n="@@Component.Filter.Billable">[i18n] Billable</label>
      <ng-select [selectOnTab]="true"
                 placeholder="[i18n] Billable"
                 i18n-placeholder="@@Component.Filter.Billable"
                 labelForId="timeSheetBillable"
                 formControlName="timeSheetBillable">
        <ng-option [value]="true" i18n="@@Common.Yes">[i18n] Yes</ng-option>
        <ng-option [value]="false" i18n="@@Common.No">[i18n] No</ng-option>
      </ng-select>
    </div>
  </ng-template>

  <!-- showDetails -->
  <ng-template #showDetails>
    <div class="col-12">
      <div class="form-check form-switch d-inline-block me-3">
        <input class="form-check-input" type="checkbox" role="switch" id="showDetails" formControlName="showDetails">
        <label class="form-check-label" for="showDetails" i18n="@@Page.Timesheet.ShowDetails">
          [i18n] Show details
        </label>
      </div>
    </div>
  </ng-template>

  <!-- filterNotImplemented -->
  <ng-template #filterNotImplemented>
    <div class="col-12">
      <label for="filterNotImplemented" class="visually-hidden">FILTER NOT IMPLEMENTED</label>
      <input type="text" readonly class="form-control-plaintext" id="filterNotImplemented" value="FILTER NOT IMPLEMENTED">
    </div>
  </ng-template>
</form>
