<div class="month-date-input">
  <input id="timeSheetStartMonth"
         type="button"
         class="form-control month-input text-center"
         [placeholder]="placeholderMonth"
         [ngModel]="value"
         (ngModelChange)="valueChanged($event)"
         tsDatePicker
         format="MMM"
         minViewMode="months"
         [relativeAnchor]="relativeAnchor"
         [clearBtn]="clearBtn"
         [startDate]="startDate"
         [endDate]="endDate"
         inputmode="none"
         autocomplete="off"
         tabindex="-1">
  <input id="timeSheetStartDate"
         type="text"
         class="form-control date-input"
         [placeholder]="placeholderDate"
         [ngModel]="value"
         (ngModelChange)="valueChanged($event)"
         tsDatePicker
         [format]="format"
         [relativeAnchor]="relativeAnchor"
         [clearBtn]="clearBtn"
         [startDate]="startDate"
         [endDate]="endDate"
         inputmode="none"
         autocomplete="off">
  <button type="button"
          class="text-secondary clear"
          [ngClass]="{'d-none': isEmpty}"
          tabindex="-1"
          (click)="valueChanged(undefined)">
    <svg class="bi text-secondary" fill="currentColor">
      <use xlink:href="assets/icons.svg#x"/>
    </svg>
  </button>
</div>
