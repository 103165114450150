<ng-template #timesheetEdit let-modal>
  <div class="modal-header">
    <h4 class="m-0" i18n="@@Page.Timesheet.Edit">[i18n] Edit entry</h4>
    <button type="button" class="btn modal-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <svg class="bi action-icon" fill="currentColor">
        <use xlink:href="assets/icons.svg#x-circle"/>
      </svg>
    </button>
  </div>
  <div class="modal-body">
    <form id="timesheetForm" [formGroup]="timesheetForm" (ngSubmit)="save()" #form="ngForm">
      <div class="row g-4">
        <div class="col-6 col-lg-3">
          <label for="startDate" class="form-label" i18n="@@DTO.TimeSheetDto.Start">[i18n] Start</label>
          <label for="startDate" class="form-label visually-hidden" i18n="@@DTO.TimeSheetDto.StartDate">[i18n] Start date</label>
          <input id="startDate" type="text" inputmode="none" tsDatePicker class="form-control" [readonly]="isReadOnly" formControlName="startDate" autocomplete="off">
        </div>
        <div class="col-6 col-lg-3">
          <label for="startTime" class="form-label">&nbsp;</label>
          <label for="startTime" class="form-label visually-hidden" i18n="@@DTO.TimeSheetDto.StartTime">[i18n] Start time</label>
          <input id="startTime" type="time" tsTime class="form-control" [readonly]="isReadOnly" formControlName="startTime" autocomplete="off">
        </div>
        <div class="col-6 col-lg-3">
          <label for="endDate" class="form-label" [tsOptionalLabel] i18n="@@DTO.TimeSheetDto.End">[i18n] End</label>
          <label for="endDate" class="form-label visually-hidden" i18n="@@DTO.TimeSheetDto.EndDate">[i18n] End date</label>
          <input id="endDate" type="text" inputmode="none" tsDatePicker relativeAnchor="end" class="form-control" [readonly]="isReadOnly" formControlName="endDate" autocomplete="off">
        </div>
        <div class="col-6 col-lg-3">
          <label for="endTime" class="form-label">&nbsp;</label>
          <label for="endTime" class="form-label visually-hidden" i18n="@@DTO.TimeSheetDto.EndTime">[i18n] End time</label>
          <input id="endTime" type="time" tsTime class="form-control" [readonly]="isReadOnly" formControlName="endTime" autocomplete="off">
        </div>
        <div class="col-lg-12">
          <label for="comment" class="form-label" [tsOptionalLabel] i18n="@@DTO.TimeSheetDto.Comment">[i18n] Comment</label>
          <textarea id="comment"
                    class="form-control"
                    [readonly]="isReadOnly"
                    rows="3"
                    formControlName="comment"
                    ngbAutofocus>
          </textarea>
        </div>
        <div class="col-lg-6">
          <!--suppress XmlInvalidId -->
          <label for="customerId" class="form-label" i18n="@@DTO.TimeSheetDto.CustomerId">[i18n] Customer</label>
          <ng-select [items]="allCustomers"
                     [selectOnTab]="true"
                     [readonly]="isReadOnly"
                     bindValue="id"
                     bindLabel="value"
                     labelForId="customerId"
                     placeholder="[i18n] Select customer"
                     i18n-placeholder="@@DTO.TimeSheetDto.CustomerIdPlaceholder"
                     (change)="selectedCustomerChanged($event)"
                     formControlName="customerId">
            <ng-template ng-option-tmp let-item="item">
              <span [ngClass]="{'text-secondary': item.hidden}">{{item.value}}</span>
            </ng-template>
          </ng-select>
        </div>
        <div class="col-lg-6">
          <!--suppress XmlInvalidId -->
          <label for="activityId" class="form-label" i18n="@@DTO.TimeSheetDto.ActivityId">[i18n] Activity</label>
          <ng-select [items]="selectableActivities"
                     [selectOnTab]="true"
                     [readonly]="isReadOnly"
                     bindValue="id"
                     bindLabel="value"
                     labelForId="activityId"
                     placeholder="[i18n] Select activity"
                     i18n-placeholder="@@DTO.TimeSheetDto.ActivityIdPlaceholder"
                     (change)="selectedActivityChanged($event)"
                     formControlName="activityId">
            <ng-template ng-option-tmp let-item="item">
              <span [ngClass]="{'text-secondary': item.hidden}">{{item.value}}</span>
            </ng-template>
          </ng-select>
        </div>
        <div class="col-lg-6">
          <!--suppress XmlInvalidId -->
          <label for="projectId" class="form-label" [tsOptionalLabel] i18n="@@DTO.TimeSheetDto.ProjectId">[i18n] Project</label>
          <ng-select [items]="selectableProjects"
                     [selectOnTab]="true"
                     [readonly]="isReadOnly"
                     bindValue="id"
                     bindLabel="value"
                     labelForId="projectId"
                     placeholder="[i18n] Select project"
                     i18n-placeholder="@@DTO.TimeSheetDto.ProjectIdPlaceholder"
                     (change)="selectedProjectChanged($event)"
                     formControlName="projectId">
            <ng-template ng-option-tmp let-item="item">
              <span [ngClass]="{'text-secondary': item.hidden}">{{item.value}}</span>
            </ng-template>
          </ng-select>
        </div>
        <div class="col-lg-6">
          <!--suppress XmlInvalidId -->
          <label for="orderId" class="form-label" [tsOptionalLabel] i18n="@@DTO.TimeSheetDto.OrderId">[i18n] Order</label>
          <ng-select [items]="selectableOrders"
                     [selectOnTab]="true"
                     [readonly]="isReadOnly"
                     bindValue="id"
                     bindLabel="value"
                     labelForId="orderId"
                     (change)="selectedOrderChanged($event)"
                     formControlName="orderId">
            <ng-template ng-option-tmp let-item="item">
              <span [ngClass]="{'text-secondary': item.hidden}">{{item.value}}</span>
            </ng-template>
          </ng-select>
        </div>
        <div class="col-lg-6">
          <label for="issue" class="form-label" [tsOptionalLabel] i18n="@@DTO.TimeSheetDto.Issue">[i18n] Issue</label>
          <input id="issue"
                 type="text"
                 class="form-control"
                 [readonly]="isReadOnly"
                 formControlName="issue">
        </div>
        <div class="col-lg-6" *ngIf="authorizationEnabled">
          <!--suppress XmlInvalidId -->
          <label for="userId" class="form-label" i18n="@@DTO.TimeSheetDto.UserId">[i18n] User</label>
          <ng-select [items]="allUsers"
                     [selectOnTab]="true"
                     [clearable]="false"
                     [readonly]="isUserReadonly"
                     bindValue="id"
                     bindLabel="value"
                     labelForId="userId"
                     placeholder="[i18n] Select user"
                     i18n-placeholder="@@DTO.TimeSheetDto.UserIdPlaceholder"
                     formControlName="userId">
            <ng-template ng-option-tmp let-item="item">
              <span [ngClass]="{'text-secondary': item.hidden}">{{item.value}}</span>
            </ng-template>
          </ng-select>
        </div>
        <div class="col-lg-12">
          <div class="form-check form-switch" [ngClass]="{disabled: isReadOnly}">
            <input class="form-check-input" type="checkbox" id="billable" formControlName="billable">
            <label class="form-label" for="billable" i18n="@@DTO.TimeSheetDto.Billable">
              [i18n] Billable
            </label>
          </div>
        </div>
      </div>
      <hr class="text-secondary" *ngIf="form.submitted && form.invalid">
      <ts-form-validation-errors></ts-form-validation-errors>
    </form>
  </div>
  <div class="modal-footer">
    <button id="cancel" form="timesheetForm" type="button" class="btn btn-secondary me-3" (click)="modal.close()">
      <ng-container i18n="@@Common.Cancel">[i18n] Cancel</ng-container>
    </button>
    <button id="save" form="timesheetForm" type="submit" class="btn btn-primary" [disabled]="isReadOnly">
      <svg class="bi check-lg" fill="currentColor">
        <use xlink:href="assets/icons.svg#check-lg"/>
      </svg>
      <ng-container *ngIf="isNewRecord && !timesheetForm.value.endDate" i18n="@@Page.Timesheet.Start">[i18n] Start</ng-container>
      <ng-container *ngIf="!isNewRecord || timesheetForm.value.endDate" i18n="@@Common.Save">[i18n] Save</ng-container>
    </button>
  </div>
</ng-template>
