<ng-template #orderEdit let-modal>
  <div class="modal-header">
    <h4 class="m-0" i18n="@@Page.MasterData.Orders.Edit">[i18n] Edit order</h4>
    <button type="button" class="btn modal-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <svg class="bi action-icon" fill="currentColor">
        <use xlink:href="assets/icons.svg#x-circle"/>
      </svg>
    </button>
  </div>
  <div class="modal-body">
    <form id="orderForm" [formGroup]="orderForm" (ngSubmit)="save()" #form="ngForm">
      <div class="row g-4">
        <div class="col-lg-12">
          <label for="title" class="form-label" i18n="@@DTO.OrderDto.Title">[i18n] Title</label>
          <input id="title"
                 type="text"
                 class="form-control"
                 [readonly]="isReadOnly"
                 placeholder="[i18n] Order name"
                 i18n-placeholder="@@DTO.OrderDto.TitlePlaceholder"
                 formControlName="title"
                 ngbAutofocus>
        </div>
        <div class="col-lg-3">
          <label for="startDate" class="form-label" i18n="@@DTO.OrderDto.StartDate">[i18n] Start date</label>
          <input id="startDate"
                 type="text"
                 inputmode="none"
                 tsDatePicker
                 class="form-control"
                 [readonly]="isReadOnly"
                 placeholder="[i18n] Begin"
                 i18n-placeholder="@@DTO.OrderDto.StartDatePlaceholder"
                 formControlName="startDate"
                 autocomplete="off">
        </div>
        <div class="col-lg-3">
          <label for="dueDate" class="form-label" i18n="@@DTO.OrderDto.DueDate">[i18n] Due date</label>
          <input id="dueDate"
                 type="text"
                 inputmode="none"
                 tsDatePicker
                 relativeAnchor="end"
                 class="form-control"
                 [readonly]="isReadOnly"
                 placeholder="[i18n] Due"
                 i18n-placeholder="@@DTO.OrderDto.DueDatePlaceholder"
                 formControlName="dueDate"
                 autocomplete="off">
        </div>
        <div class="col-lg-6">
          <!--suppress XmlInvalidId -->
          <label for="customerName" class="form-label" i18n="@@DTO.OrderDto.CustomerId">[i18n] Customer</label>
          <ng-select [items]="customers$ | async"
                     [selectOnTab]="true"
                     [readonly]="isReadOnly"
                     bindValue="id"
                     bindLabel="value"
                     labelForId="customerName"
                     placeholder="[i18n] Select customer"
                     i18n-placeholder="@@DTO.OrderDto.CustomerIdPlaceholder"
                     formControlName="customerId">
            <ng-template ng-option-tmp let-item="item">
              <span [ngClass]="{'text-secondary': item.hidden}">{{item.value}}</span>
            </ng-template>
          </ng-select>
        </div>
        <div class="col-lg-3">
          <label for="hourlyRate" class="form-label" [tsOptionalLabel]="{short: true}" i18n="@@DTO.OrderDto.HourlyRate">[i18n] Hourly rate</label>
          <input id="hourlyRate" type="text" inputmode="decimal" [tsNumeric]="{fractionDigits:2}" class="form-control" [readonly]="isReadOnly" formControlName="hourlyRate">
        </div>
        <div class="col-lg-3">
          <label for="budget" class="form-label" i18n="@@DTO.OrderDto.Budget">[i18n] Budget</label>
          <input id="budget" type="text" inputmode="decimal" [tsNumeric]="{fractionDigits:2}" class="form-control" [readonly]="isReadOnly" formControlName="budget">
        </div>
        <div class="col-lg-6">
          <label for="number" class="form-label" [tsOptionalLabel] i18n="@@DTO.OrderDto.Number">[i18n] Order number</label>
          <input id="number" type="text" class="form-control" [readonly]="isReadOnly" formControlName="number">
        </div>
        <div class="col-lg-12">
          <label for="description" class="form-label" [tsOptionalLabel] i18n="@@DTO.OrderDto.Description">[i18n] Description</label>
          <textarea id="description" class="form-control" [readonly]="isReadOnly" formControlName="description" rows="2"></textarea>
        </div>
        <div class="col-lg-12">
          <label for="comment" class="form-label" [tsOptionalLabel] i18n="@@DTO.OrderDto.Comment">[i18n] Comment</label>
          <textarea id="comment" class="form-control" [readonly]="isReadOnly" formControlName="comment" rows="2"></textarea>
        </div>
        <div class="col-lg-12">
          <div class="form-check form-switch" [ngClass]="{disabled: isReadOnly}">
            <input class="form-check-input" type="checkbox" id="hidden" formControlName="hidden">
            <label class="form-label" for="hidden" i18n="@@DTO.OrderDto.Hidden">
              [i18n] Hidden
            </label>
          </div>
        </div>
      </div>
      <hr class="text-secondary" *ngIf="form.submitted && form.invalid">
      <ts-form-validation-errors></ts-form-validation-errors>
    </form>
  </div>
  <div class="modal-footer">
    <button id="cancel" form="orderForm" type="button" class="btn btn-secondary me-3" (click)="modal.close()">
      <ng-container i18n="@@Common.Cancel">[i18n] Cancel</ng-container>
    </button>
    <button id="save" form="orderForm" type="submit" class="btn btn-primary" [disabled]="isReadOnly">
      <svg class="bi check-lg" fill="currentColor">
        <use xlink:href="assets/icons.svg#check-lg"/>
      </svg>
      <ng-container i18n="@@Common.Save">[i18n] Save</ng-container>
    </button>
  </div>
</ng-template>
