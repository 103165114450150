<div class="row">
  <!-- Time sheet -->
  <div class="col-12 col-md-8 col-xl-9 order-last order-md-first px-4">
    <ng-container *ngTemplateOutlet="timeSheet"></ng-container>
  </div>
  <div class="col-12 col-md-4 col-xl-3 order-first order-md-last px-4 px-md-2 ps-md-2 ps-lg-5 pb-5 pb-md-0 filter">
    <!-- Filter -->
    <ts-filter filterId="filterTimeSheet"
               [filters]="filters">
    </ts-filter>
  </div>
</div>

<ng-template #timeSheet>
  <ng-container *ngIf="overview">
    <!-- Header -->
    <div class="header d-none d-lg-block">
      <ng-container *ngTemplateOutlet="headerButtons"></ng-container>
    </div>

    <ng-template #headerButtons>
      <div class="header-action">
        <button *ngIf="!overview.runningTimeSheets.length" type="button" class="btn start" [routerLink]="guidService.guidEmpty" [disabled]="createNewDisabled">
          <svg class="bi" fill="currentColor">
            <use xlink:href="assets/icons.svg#play-circle-fill"/>
          </svg>
        </button>
        <button *ngIf="overview.runningTimeSheets.length" type="button" class="btn stop" (click)="stopAllTimeSheets()" [disabled]="createNewDisabled">
          <svg class="bi" fill="currentColor">
            <use xlink:href="assets/icons.svg#stop-circle-fill"/>
          </svg>
        </button>
        <button *ngIf="overview.runningTimeSheets.length" type="button" class="btn edit" [routerLink]="guidService.guidEmpty" [disabled]="createNewDisabled">
          <svg class="bi" fill="currentColor">
            <use xlink:href="assets/icons.svg#plus-circle-fill"/>
          </svg>
        </button>
      </div>
    </ng-template>

    <!-- Table -->
    <div class="data">
      <!-- Header -->
      <div class="row table-header mb-1 mx-0 small">
        <div class="col-4 col-md-3 col-xl-auto">
          <div class="row gx-2">
            <strong class="col-12 col-xl-auto col-start-end" i18n="@@DTO.TimeSheetGridDto.Time">[i18n] Time</strong>
            <strong class="col-12 col-xl-auto col-duration" i18n="@@DTO.TimeSheetGridDto.Duration">[i18n] Duration</strong>
          </div>
        </div>
        <div class="col-6 col-md">
          <div class="row">
            <strong class="col-12 col-lg-3" i18n="@@DTO.TimeSheetGridDto.Customer">[i18n] Customer</strong>
            <strong class="col-12 col-lg-5" i18n="@@DTO.TimeSheetGridDto.Activity">[i18n] Activity</strong>
            <strong class="col-12 col-lg-4" i18n="@@DTO.TimeSheetGridDto.Project">[i18n] Project</strong>
          </div>
        </div>
        <div class="col-2 col-md-auto col-action">
          <strong class="text-nowrap visually-hidden" i18n="@@Common.Action">[i18n] Action</strong>
          <div class="d-block d-lg-none">
            <ng-container *ngTemplateOutlet="headerButtons"></ng-container>
          </div>
        </div>
      </div>

      <!-- Data -->
      <ng-container *ngFor="let workDay of overview.workDayTimeSheets; let first=first; trackBy:timeSheetDayGroupKey;">
        <!-- Workday header -->
        <div class="row mx-0 row-day-header" [ngClass]="{'first':first}">
          <span class="col">
            <span class="fw-bold pe-1">
              {{workDay.date | tsDate:'cccc'}},
              {{workDay.date | tsDate}}
            </span>
            ({{workDay.workTime | tsDuration}})
          </span>
        </div>

        <!-- Workday entries -->
        <div *ngFor="let timeSheet of workDay.timeSheets; let last=last; trackBy:timeSheetRowKey;"
             class="data-row border-light"
             [ngClass]="{'row-running': !timeSheet.endDate, 'border-bottom': !last}">

          <!-- Timesheet row main -->
          <div class="row mx-0" [ngClass]="{'text-secondary': !timeSheet.billable}">
            <div class="col-4 col-md-3 col-xl-auto">
              <div class="row gx-2">
                <div class="col-12 col-xl-auto col-start-end">{{timeSheet.startDate| tsTime}}
                  <ng-container *ngIf="timeSheet.endDate">- {{timeSheet.endDate | tsTime}}</ng-container>
                  <ng-container *ngIf="!timeSheet.endDate">-
                    <ng-container i18n="@@DTO.TimeSheetGridDto.Now">[i18n] jetzt</ng-container>
                  </ng-container>
                </div>
                <div class="col-12 col-xl-auto col-duration">{{timeSheet.duration | tsDuration}}
                </div>
              </div>
            </div>
            <div class="col-6 col-md">
              <div class="row">
                <div class="col-12 col-lg-3">{{timeSheet.customerTitle}}</div>
                <div class="col-12 col-lg-5">{{timeSheet.activityTitle}}</div>
                <div class="col-12 col-lg-4">{{timeSheet.projectTitle}}</div>
              </div>
            </div>
            <div class="col-2 col-md-auto col-action">
            <span class="d-block d-md-inline me-md-2">
              <a class="" (click)="restartTimeSheet(timeSheet.id)" [ngClass]="{ disabled: createNewDisabled }">
                <svg *ngIf="timeSheet.endDate" class="bi action-icon" fill="currentColor">
                  <use xlink:href="assets/icons.svg#play-circle"/>
                </svg>
              </a>
              <a class="" (click)="stopTimeSheet(timeSheet.id)">
                <svg *ngIf="!timeSheet.endDate" class="bi action-icon" fill="currentColor">
                  <use xlink:href="assets/icons.svg#stop-circle"/>
                </svg>
              </a>
            </span>
              <a class="d-block d-md-inline me-md-2"
                 title="[i18n] Edit Activity"
                 i18n-title="@@Page.Timesheet.Edit"
                 [routerLink]="timeSheet.id">
                <svg class="bi action-icon" fill="currentColor">
                  <use xlink:href="assets/icons.svg#pencil"/>
                </svg>
              </a>
              <ts-simple-confirm
                title="[i18n] Delete Entry"
                i18n-title="@@Page.Timesheet.Delete"
                message="[i18n] Are you sure you want to delete this entry? This cannot be undone."
                i18n-message="@@Page.Timesheet.DeleteMessage"
                actionText="[i18n] Delete Entry"
                i18n-actionText="@@Page.Timesheet.Delete"
                color="danger"
                [disabled]="timeSheet.isReadonly ?? false"
                (confirmed)="deleteItem(timeSheet.id)">
                <a class="">
                  <svg class="bi action-icon" fill="currentColor">
                    <use xlink:href="assets/icons.svg#eraser"/>
                  </svg>
                </a>
              </ts-simple-confirm>
            </div>
          </div>

          <!-- Timesheet row detail -->
          <div *ngIf="showDetails" class="row mx-0 pt-1" [ngClass]="{'row-running': !timeSheet.endDate}">
            <div *ngIf="timeSheet.username" class="col-auto">
              <span class="badge fw-normal">
                {{timeSheet.username}}
              </span>
            </div>
            <div *ngIf="timeSheet.issue" class="col-auto">
              <span class="badge fw-normal">
                {{timeSheet.issue}}
              </span>
            </div>
            <div *ngIf="timeSheet.orderTitle" class="col-auto">
              <span class="badge fw-normal">
                {{timeSheet.orderTitle}}
              </span>
            </div>
            <div *ngIf="timeSheet.comment" class="col-auto comment">
              <svg class="bi" fill="currentColor">
                <use xlink:href="assets/icons.svg#chat-left-text"/>
              </svg>
              {{timeSheet.comment}}
            </div>
          </div>
        </div>
      </ng-container>

      <!-- Footer -->
      <div *ngIf="overview.omittedTimeSheets > 0" class="row table-footer mx-0">
        <div class="col px-0">
          <div class="alert alert-warning m-2" role="alert">
            <svg class="bi me-2" fill="currentColor">
              <use xlink:href="assets/icons.svg#exclamation-circle"/>
            </svg>
            <ng-container i18n="@@Page.Timesheet.OmittedEntities">
              [i18n] {{overview.omittedTimeSheets //i18n(ph="OMITTED")}} additional entries have been omitted to prevent performance issues.
            </ng-container>
          </div>
        </div>
      </div>

    </div>
  </ng-container>
</ng-template>

<router-outlet></router-outlet>
